
import mixins from 'vue-typed-mixins';
import { SelectOption } from '@/types';
import NotificationMixin from '@/mixins/NotificationMixin';
import { mapGetters } from 'vuex';
import MasterService from '@/common/services/MasterService';
import SupplierPortalLinkedMastersTableForm from './SupplierPortalLinkedMastersTableForm.vue';

type SupplierLinkedMastersDataTableItem = {
  id: string;
  name: string;
};

type SupplierLinkedMastersDataTable = SupplierLinkedMastersDataTableItem[];

export default mixins(NotificationMixin).extend({
  name: 'SupplierPortalLinkedMastersSectionForm',

  components: {
    SupplierPortalLinkedMastersTableForm,
    SupplierPortalLinkedMastersDropdown: () => import('./SupplierPortalLinkedMastersDropdown.vue'),
  },

  props: {
    source: {
      type: Array as () => string[],
      required: true,
    },
  },

  data() {
    return {
      masters: {
        hasErrors: false,
        options: [] as SelectOption[],
        selected: null,
      },
      dataTable: [] as SupplierLinkedMastersDataTable,
    };
  },

  computed: {
    ...mapGetters({
      supplier: 'supplier/supplier',
    }),

    masterIdList(): string[] {
      return this.supplier.masters;
    },

    masterOptionsAvailable(): SelectOption[] {
      const { options } = this.masters;

      return options
        .filter(
          (option) => !this.dataTable
            .find((item) => (
              item.id === option.optionValue
            )),
        );
    },

    masterDropdownSource: {
      get(): { options: SelectOption[]; selected: string | null} {
        const options = this.masterOptionsAvailable;
        return {
          options,
          selected: null,
        };
      },
    },
  },

  async mounted() {
    await this.loadMasters();

    this.dataTable = this.source
      .map((value) => {
        const option = this.masters.options.find(
          (item) => item.optionValue === value,
        );

        return {
          id: option?.optionValue,
          name: option?.optionLabel || '',
        };
      })
      .filter((item) => item.id) as SupplierLinkedMastersDataTable;
  },

  methods: {

    async loadMasters() {
      const data = await MasterService.getMastersNames(this.masterIdList);
      try {
        this.masters.options = data.map((item) => ({
          optionLabel: item.name,
          optionValue: item.id,
        }) as SelectOption) || [];
      } catch (error) {
        const message = this.$t('profileManagement.alerts.failLoadCompanyGroups.message') as string;
        this.notifyError(message);
        console.log(error);
      }
    },

    addSupplierLinkedMaster() {
      const { selected, options } = this.masterDropdownSource;

      if (!selected) {
        return;
      }

      const option = options.find((item) => item.optionValue === selected);

      const {
        optionValue,
        optionLabel,
      } = option as SelectOption;

      const master = {
        id: optionValue,
        name: optionLabel,
      };
      this.dataTable.push(master);
    },

    notifyError(message: string) {
      this.warningToast({ text: message });
    },

  },

  watch: {
    dataTable: {
      deep: true,
      handler(dataTable: SupplierLinkedMastersDataTable) {
        this.$emit('update:source', dataTable.map((item) => item.id));
      },
    },
  },
});
