
import mixins from 'vue-typed-mixins';
import ProfileManagementFormMixin from '@/resources/profile-management/components/forms/ProfileManagementFormMixin';

type SortOption = 'asc' | 'desc'
type ColOption = 'name'
type DataTable = Array<{id: string; name: string}>

const SORT_DEFAULT = 'asc' as SortOption;

export default mixins(ProfileManagementFormMixin).extend({
  name: 'SupplierPortalLinkedMastersTableForm',

  props: {
    source: {
      type: Array as () => DataTable,
      default: () => ([]),
    },
  },

  data() {
    return {
      data: [] as DataTable,
      orderBy: {
        col: 'name' as ColOption,
        sort: 'asc' as SortOption,
      },
    };
  },

  computed: {
    hasData(): boolean {
      return this.data.length > 0;
    },

    dataTable(): DataTable {
      const { col, sort } = this.orderBy;

      const source = this.data.sort((a, b) => {
        if (a[col] === b[col]) {
          return 0;
        }

        let compare = a[col] > b[col]; // asc default

        if (sort === 'desc') {
          compare = a[col] < b[col];
        }

        return compare ? 1 : -1;
      });

      return source;
    },
  },

  methods: {

    removeItem(index: number): void {
      this.source.splice(index, 1);
    },

    setOrderBy(colOption: ColOption, sortOption?: SortOption) {
      const sortAlternated = this.orderBy.sort === 'asc' ? 'desc' : 'asc';
      const isSameCol = this.orderBy.col === colOption;
      const sort = sortOption ?? (isSameCol ? sortAlternated : SORT_DEFAULT);

      this.orderBy = {
        col: colOption,
        sort,
      };
    },
  },

  watch: {
    source: {
      immediate: true,
      handler(source: DataTable): void {
        this.data = JSON.parse(JSON.stringify(source)) as DataTable;
      },
    },
  },

});
