var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "fieldset",
    { staticClass: "mt-2" },
    [
      _c("legend", [
        _vm._v(
          _vm._s(
            _vm.$t("profileManagement.form.section.supplierPortalLinkedMaster")
          )
        ),
      ]),
      _c("div", { staticClass: "p-col-12" }, [
        _c(
          "div",
          { staticClass: "p-grid p-fluid" },
          [
            _c("SupplierPortalLinkedMastersDropdown", {
              attrs: {
                source: _vm.masterDropdownSource,
                label: _vm.$t(
                  "profileManagement.form.supplierLinkedMaster.label"
                ),
                placeholder: _vm.$t(
                  "profileManagement.form.supplierLinkedMaster.placeholder"
                ),
                "has-attached": _vm.dataTable && _vm.dataTable.length > 0,
              },
              on: {
                hasErrors: function ($event) {
                  _vm.masters.hasErrors = $event
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "pl-2",
                class: {
                  "self-end": !_vm.masters.hasErrors,
                  "self-center": _vm.masters.hasErrors,
                },
              },
              [
                _c("Button", {
                  staticClass: "outlined p-button-raised",
                  attrs: { label: "Vincular" },
                  on: { click: _vm.addSupplierLinkedMaster },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("SupplierPortalLinkedMastersTableForm", {
        attrs: { source: _vm.dataTable },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }