var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.hasData
    ? _c("div", { staticClass: "component-table" }, [
        _c("div", { staticClass: "col-md-12 component-head" }, [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "flex-1 cell" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "profileManagement.supplierPortalLinkedMasterTableForm.name"
                    )
                  ) +
                  " "
              ),
              _c("i", {
                staticClass: "icon-r-sort-1 cursor-pointer",
                on: { click: () => _vm.setOrderBy("name") },
              }),
            ]),
            _vm._m(0),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "component-body" },
          _vm._l(_vm.dataTable, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "col-md-12 component-row" },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "flex-1 cell", attrs: { title: item.name } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  ),
                  _c("div", { staticClass: "cell actions icon-remove" }, [
                    _c("i", {
                      staticClass: "icon-r-circle-close",
                      on: { click: () => _vm.removeItem(index) },
                    }),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "cell actions" }, [
      _c("div", { staticClass: "w-4" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }